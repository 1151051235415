<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>退换货管理</el-breadcrumb-item>
        <el-breadcrumb-item>退货列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.label" :name="item.name" v-for="(item,index) in orderStatus"
                       :key="index"></el-tab-pane>
        </el-tabs>
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">退货单号：</div>
                <el-input v-model="search.refundNo" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">快递单号：</div>
                <el-input v-model="search.expressNumber" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">退货人手机：</div>
                <el-input v-model="search.phone" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">退货人姓名：</div>
                <el-input v-model="search.createUserName" placeholder="搜索"></el-input>
              </div>
              </div>
          </div>
          <div class="mt-10 flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">快递公司：</div>
                <el-input v-model="search.expressName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">退货原因：</div>
                <el-select @visible-change="showReason" v-model="search.reason" placeholder="请选择">
                  <el-option
                      v-for="(item,index) in reason"
                      :key="index"
                      :label="item.reason"
                      :value="item.reason">
                  </el-option>
                </el-select>
              </div>

              <div class="flexRow alignItems margin-btn">
                <div class="search-title">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ command.label }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="(item,index) in timeStatus" :command="item.value" :key="index">
                        {{ item.label }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <el-date-picker
                    v-model="datePicker"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    @change="changePicker"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>

            <div class="flexRow">
              <el-button type="primary" v-if="orderRefundBatchTake" @click="goBatchTake(1)">批量收货</el-button>
              <el-button type="primary" v-if="orderRefundBatchRefund" @click="goBatchTake(2)">批量退款</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="refundNo" :show-overflow-tooltip="true" label="退货单号"
                             :min-width="150"></el-table-column>
            <el-table-column align="center" prop="expressNumber" :show-overflow-tooltip="true" label="快递单号"
                             :min-width="150">
              <template slot-scope="scope">{{
                  scope.row.expressNumber ? scope.row.expressNumber : '-'
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="createDate" label="申请时间" :min-width="110"></el-table-column>
            <el-table-column align="center" prop="orderCommodityInfos.payCount" label="商品数量"></el-table-column>
            <el-table-column align="center" label="退款金额(元)">
              <template slot-scope="scope">{{
                  scope.row.refundAmount ? (scope.row.refundAmount.toFixed(2)) / 100 : '0.00'
                }}元
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reason" :show-overflow-tooltip="true" label="换货原因"
                             :min-width="150"></el-table-column>
            <el-table-column align="center" prop="recipientAddress" label="退货状态" min-width="120">
              <template slot-scope="scope"> {{scope.row.status | getOrderText}}
                <el-tag v-if="false" effect="light" :color="scope.row.status | getOrderStatusType">{{
                    scope.row.status | getOrderText
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="170">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="scope.row.status === 1 && orderRefundAgreeRefund"
                       @click="agreeRefundGood(scope.row.id)">同意退货
                  </div>
                  <div class="border_center_left" v-if="scope.row.status === 1 && orderRefundAgreeRefund"
                       @click="refuseRefundGood(scope.row.id)">拒绝换货
                  </div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        title="同意退货"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="shipmentsData && formDataReady" :model="shipmentsData" :rules="rules" ref="ruleForm">
        <el-form-item label="收货地址" prop="deliveryAddressId">
          <el-select v-model="shipmentsData.deliveryAddressId" placeholder="请选择收货地址">
            <el-option
                v-for="(item,index) in deliveryAddress"
                :key="index"
                :label="item.simAddress+item.detailAddress"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否退运费：" prop="isRefundFreight">
          <el-select v-model="shipmentsData.isRefundFreight" placeholder="请选择是否退运费">
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remarks" style="width: 100%;">
          <el-input
              type="textarea"
              placeholder="请输入备注"
              v-model="shipmentsData.remarks"
              :autosize="{ minRows: 6, maxRows: 8}"
              maxlength="300"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitShipments">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="订单状态"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleOrder"
        width="30%"
        :before-close="handleClose1"
    >
      <el-form v-if="eidtStatus && formDataReady" :model="eidtStatus" :rules="rules1" ref="ruleForm">
        <el-form-item label="订单状态：" prop="orderStatus">
          <el-select v-model="eidtStatus.orderStatus" placeholder="请选择">
            <el-option
                v-for="item in orderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="editStatusBtn">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="拒绝退货"
        :close-on-click-modal="false"
        :visible.sync="refuseDialogVisible"
        width="30%"
        :before-close="handleCloseRefuse"
    >
      <el-form v-if="refuseData && formDataReady" :model="refuseData" :rules="refuseRules" ref="ruleRefuse">
        <el-form-item label="备注：" prop="refuseReason" style="width: 100%;">
          <el-input
              type="textarea"
              placeholder="请输入备注"
              v-model="refuseData.refuseReason"
              :autosize="{ minRows: 6, maxRows: 8}"
              maxlength="300"
              show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseRefuse">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitRefuseAfter">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    return {
      search: {
        refundNo: '',
        expressNumber: '',
        phone: '',
        createUserName: '',
        expressName: '',
        reason: '',
        createDate: '',
        takeDate: '',
        refundDate: '',
        completeDate: '',
        status: 0,
      },
      currentTab: "",
      input: "",
      options: [],
      tableList: [],
      value: "",
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogVisibleOrder: false,
      shipmentsData: { // 确认发货
        id: '',
        expressNumber: '',
        expressName: '圆通',
        shipmentsDate: '',
        orderStatus: 3
      },
      eidtStatus: { // 订单状态
        id: '',
        orderStatus: null
      },
      rules1: {
        // orderStatus: [{required: true, message: "请选择订单状态", trigger: "change"}]
      },
      rules: {
        deliveryAddressId: [{required: true, message: "请选择收货地址", trigger: "change"}],
        isRefundFreight: [{required: true, message: "请选择是否退运费", trigger: "change"}]
      },
      refuseDialogVisible: false,
      refuseData: {
        refuseReason: '',
        id: '',
      },
      refuseRules: {
        refuseReason: [{required: true, message: "请输入备注", trigger: "change"}],
      },
      allState: [
        {label: '否', value: 0},
        {label: '是', value: 1}
      ],
      command: {label: '申请时间', value: 'createDate'},
      // 时间类型
      timeStatus: [
        {label: '申请时间', value: 'createDate'},
        {label: '收货时间', value: 'takeDate'},
        {label: '退款时间', value: 'refundDate'},
        {label: '完成时间', value: 'completeDate'},
      ],
      // 状态： 0全部 1待审核 2待收货 3待退款 4待到账 5完成 6拒绝
      orderStatus: [
        {label: '全部',text: '全部', name: 'all', value: 0, color: '#409EFF'},
        {label: '待审核',text: '待审核', name: 'audit', value: 1, color: '#409EFF'},
        {label: '待收货',text: '待收货', name: 'takeDelivery', value: 2, color: '#F56C6C'},
        {label: '待退款',text: '待退款', name: 'refund', value: 3, color: '#b05bff'},
        {label: '待到账',text: '待到账', name: 'toAccount', value: 4, color: '#67C23A'},
        {label: '已完成',text: '已完成', name: 'complete', value: 5, color: '#f988ff'},
        {label: '拒绝',text: '拒绝', name: 'refuse', value: 6, color: '#909399'},
        {label: '待用户寄件',text: '待用户寄件', name: 'agree', value: 7, color: '#f5318d'},
        {label: '用户取消', text: '用户取消', name: 'refuse2', value: 8, color: '#31bdf5'},
      ],
      activeName: 'all', // tabs
      orderInfoDel: false,
      orderInfoUpd: false,
      orderRefundAgreeRefund: false,
      orderRefundBatchTake: false,
      orderRefundBatchRefund: false,
      total: 0,
      page: 1,
      pageSize: 10,
      dialogImgVisible: false,
      dialogImgUrl: "",
      datePicker: [], // 下单时间
      countData: {},
      deliveryAddress: [],
      reason: [],
    };
  },
  filters: {
    getState(value) {
      const data = _this.allState.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getOrderText(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].text;
      }
    },
    getOrderStatusType(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].color;
      }
    },
    getOrderStatus(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
    this.getOrderCountData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: _this.page,
        pageSize: _this.pageSize,
        search: {}
      }

      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }


      _this.$api
          .getOrderRefund(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取订单各状态数量
    getOrderCountData() {
      let _this = this;
      _this.$api.refundOrderCount().then(res => {
        if (res.data.code == 200) {
          _this.countData = res.data.data;
          _this.orderStatus.forEach(item => {
            for (let key in _this.countData) {
              if (item.name == key) {
                if (this.countData[key] != 0 && this.countData[key] != null) {
                  item.label = item.label + '(' + _this.countData[key] + ')'
                }
              }
            }
          })
        }
      }).catch(() => {

      });
    },
    // 切换订单状态
    handleClick(tab, event) {
      // console.log(tab, event);
      let _this = this;
      _this.currentTab = tab.paneName;
      _this.orderStatus.forEach(item => {
        if (item.name === tab.paneName) {
          if (tab.paneName === 'all') {
            _this.search.status = '';
          } else {
            _this.search.status = item.value;
          }
        }
      })
      _this.page = 1;
      _this.getData();
    },
    // 搜索时间
    handleCommand(command) {
      let _this = this;
      _this.search.createDate = '';
      _this.search.takeDate = '';
      _this.search.refundDate = '';
      _this.search.completeDate = '';
      _this.datePicker = [];
      const list = _this.timeStatus.filter(item => item.value === command);
      if (list) {
        _this.command = list[0]
      }
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "orderInfo:del") {
          _this.orderInfoDel = true;
        }
        if (v == "orderInfo:upd") {
          _this.orderInfoUpd = true;
        }
        if (v == "orderRefund:agreeRefund") {
          _this.orderRefundAgreeRefund = true;
        }
        if (v == "orderRefund:batchTake") {
          _this.orderRefundBatchTake = true;
        }
        if (v == "orderRefund:batchRefund") {
          _this.orderRefundBatchRefund = true;
        }
      })
    },
    // 下单时间
    changePicker(e) {
      let _this = this;
      _this.search.createDate = '';
      _this.search.takeDate = '';
      _this.search.refundDate = '';
      _this.search.completeDate = '';
      if (_this.command.value === 'createDate') {
        _this.search.createDate = e[0];
      }
      if (_this.command.value === 'takeDate') {
        _this.search.takeDate = e[0];
      }
      if (_this.command.value === 'refundDate') {
        _this.search.refundDate = e[0];
      }
      if (_this.command.value === 'completeDate') {
        _this.search.completeDate = e[0];
      }
      _this.search.updateDate = e[1];
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;

      _this.search = {
        refundNo: '',
        expressNumber: '',
        phone: '',
        createUserName: '',
        expressName: '',
        reason: '',
        createDate: '',
        takeDate: '',
        refundDate: '',
        completeDate: '',
        status: 0,
      }
      if (_this.currentTab === 'all') {
        _this.search.status = '';
      } else {
        _this.search.status = Number(_this.currentTab);
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 批量收货
    goBatchTake(type) {
      let _this = this;
      if(type === 1) {
        _this.$router.push("/batchTake");
      }
      if(type === 2) {
        _this.$router.push("/batchRefund");
      }
    },
    details(e) {
      let _this = this;
      _this.$router.push(
          "/orderRefundDetails?goods=" + encodeURIComponent(JSON.stringify(e))
      );
    },
    edit(e) {
      let _this = this;
      _this.$router.push(
          "/addCommodityInfo?type=edit&goodsId=" + e.id
      );
    },
    // 获取当前时间 格式：yyyy-MM-dd HH:MM:SS
    getCurrentTime() {
      let _this = this;
      let date = new Date();//当前时间
      let month = _this.zeroFill(date.getMonth() + 1);//月
      let day = _this.zeroFill(date.getDate());//日
      let hour = _this.zeroFill(date.getHours());//时
      let minute = _this.zeroFill(date.getMinutes());//分
      let second = _this.zeroFill(date.getSeconds());//秒

      //当前时间
      let curTime = date.getFullYear() + '-' + month + '-' + day
          + " " + hour + ':' + minute + ':' + second;

      return curTime;
    },
    // 补零
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return '0' + i;
      } else {
        return i;
      }
    },
    // 拒绝换货
    refuseRefundGood(id) {
      let _this = this;
      _this.refuseDialogVisible = true;
      _this.refuseData = {
        id: id,
        refuseReason: ''
      };
      _this.handleInitForm();
    },
    // 同意退货
    agreeRefundGood(id) {
      let _this = this;
      _this.dialogVisible = true;
      _this.shipmentsData = {
        id: id,
        deliveryAddressId: '',
        deliveryAddress: '',
        deliveryName: '',
        deliveryPhone: '',
        isRefundFreight: null,
        remarks: '',
      };
      if(_this.deliveryAddress.length===0) {
        _this.getAddressList();
      }
      _this.handleInitForm();
    },
    // 关闭拒绝换货
    handleCloseRefuse() {
      let _this = this;
      _this.shipmentsData = {
        id: '',
        remark: '',
      };
      _this.handleInitForm();
      _this.refuseDialogVisible = false;
    },
    submitRefuseAfter() {
      let _this = this;
      _this.btnLoading = true;

      _this.$refs["ruleRefuse"].validate(valid => {
        if (valid) {
          _this.$api.refuseAfter(_this.refuseData).then(res => {
            if (res.data.code == 200) {
              _this.$message.success("操作成功");
              _this.btnLoading = false;
              _this.refuseDialogVisible=false;
              _this.handleClose();
              _this.getData();
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        } else {
          _this.btnLoading = false;
        }
      });
    },
    //
    showAddress(e){
      let _this = this;
      if(e && _this.deliveryAddress.length===0) {
        _this.getAddressList();
      }
    },
    // 是否获取退货原因
    showReason(e){
      let _this = this;
      if(e && _this.reason.length===0) {
        _this.getCancelData();
      }
    },
    // 获取退货原因
    getCancelData() {
      let _this = this;

      const requestData = {
        page: 1,
        pageSize: 1000,
        search: {
          type: 2,
          status: 1
        }
      }
      _this.$api
          .getCancel(requestData)
          .then(res => {
            if (res.data.code == 200) {
              const list = res.data.data.records;
              _this.reason = list;
            }
          }).catch(() => {
      });
    },

    // 获取收货地址
    getAddressList() {
      let _this = this;
      const requestData = {
        page: 1,
        pageSize: 1000,
        search: {
          takeAddress: 1
        },
      }
      _this.$api
          .getAddress(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.deliveryAddress  = res.data.data.records;
              return
              const list = res.data.data.records;
              if (list.length>0) {
                list.forEach(item => {
                  _this.deliveryAddress.push({
                    id: item.id,
                    label: item.simAddress+item.detailAddress
                  })
                })
              }
            }
          }).catch(() => {
      });
    },

    // 确认发货-提交
    submitShipments() {
      let _this = this;
      _this.btnLoading = true;

      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          //YT2173006235891
          // _this.shipmentsData.shipmentsDate = _this.getCurrentTime();
          const address = _this.deliveryAddress.filter(item => item.id = _this.shipmentsData.deliveryAddressId);

          _this.shipmentsData.deliveryAddress =  address[0].simAddress + address[0].detailAddress;
          _this.shipmentsData.deliveryName =  address[0].shipName;
          _this.shipmentsData.deliveryPhone =  address[0].phone;

          const requestData = JSON.parse(JSON.stringify(_this.shipmentsData));
          delete requestData.deliveryAddressId
          // return
          _this.$api.agreeRefund(requestData).then(res => {
            if (res.data.code == 200) {
              _this.$message.success("操作成功");
              _this.btnLoading = false;
              _this.handleClose();
              _this.getData();
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        } else {
          _this.btnLoading = false;
        }
      });
    },
    // 修改订单状态
    changeStatus(id) {
      let _this = this;
      _this.dialogVisibleOrder = true;
      _this.eidtStatus = {
        id,
        orderStatus: null
      };
      _this.handleInitForm();
    },
    editStatusBtn() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          _this.$api.editOrderInfo(_this.eidtStatus).then(res => {
            if (res.data.code == 200) {
              _this.$message.success("操作成功");
              _this.btnLoading = false;
              _this.handleClose1();
              _this.getData();
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose1() {
      let _this = this;
      _this.eidtStatus = {
        id: '',
        orderStatus: null
      };
      _this.handleInitForm();
      _this.dialogVisibleOrder = false;
    },
    // 删除
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delOrderInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },

    // 关闭确认发货
    handleClose() {
      let _this = this;
      _this.shipmentsData = {
        id: '',
        expressNumber: '',
        expressName: '圆通',
        orderStatus: 3
      };
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
  }
};
</script>
<style scoped lang="less">
.between-text {
  margin: 0 10px;
}

/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-tag {
  color: #fff !important;
  border: none;
}

.attrsAssociateImage {
  width: 50px;
  height: 50px;
}

.commodityName {
  margin-left: 10px;
  text-align: left;
}

.recipient {
  text-align: left;
}

.el-dropdown-link {
  cursor: pointer;
  margin-right: 10px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>

